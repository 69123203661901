// src/analytics.js
import ReactGA from 'react-ga4';

export const initGA = () => {
  ReactGA.initialize('G-9Z7GE8E7F5'); // Substitua pelo seu ID de Medição
};

export const logPageView = () => {
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
};

export const logEvent = (category = '', action = '', label = '') => {
  ReactGA.event({
    category: 'Ações',
    action: 'Código Pesquisado',
    label: label,
    nonInteraction: false,
    transport: 'beacon',
    // Adicionar parâmetros personalizados
    customParameters: {
      codigo_pesquisado: label, // Parâmetro personalizado
    },
  });
};

export const logCodigo = (codigo = '') => {
  ReactGA.gtag('event', 'ticket_buscado', {
    'codigo': codigo,
  })
};

export const logErro = (codigo = '') => {
  ReactGA.gtag('event', 'ticket_buscado_erro', {
    'codigo': codigo,
  })
};